<template>
  <div v-if="!isLoadingData" class="m-2">
    <public-group-header :agent="currentAgent" :agencyName="tenantConfig.name"/>
    <b-tabs
      ref="reservComp"
      class="mt-2 mx-2"
      content-class="mt-2"
      justified
      v-model="tabIndex"
    >
      <b-tab title="1. Forfait & Chambre">
        <group-reservation-package
          :agents="users"
          :current-agent="currentAgent"
          :current-auth-user="currentAuthUser"
          ref="packagecompon"
          :current-packages="currentPackages"
          :unavailable-packages="unavailablePackages"
          :current-reservation="currentReservation"
          @submitUpdateReservationPackage="submitUpdateReservationPackage"
          @submitReservationPackage="submitReservationPackage"
          @nextTab="tabIndex++"
        />
      </b-tab>
      <b-tab
        title="2. Informations des voyageurs"
        :disabled="this.activateTabs"
        :title-item-class="{ 'text-danger': errors }"
      >
        <group-reservation-travelers
          :current-auth-user="currentAuthUser"
          :has-primary="hasPrimary"
          :errors="errors"
          ref="groupTravelers"
          :is-public="true"
          :current-reservation="currentReservation"
          @updateTravelerReservationPackage="updateTravelerReservationPackage"
          @deleteTravelerAll="deleteTravelerAll"
          @postTravelerReservationPackage="postTravelerReservationPackage"
          @updateRoomPreferences="updateRoomPreferences"
          @nextTab="tabIndex++"
        />
      </b-tab>
      <b-tab
        title="3. Informations supplémentaires"
        :disabled="this.activateTabs"
      >
        <group-reservation-details
          ref="detailsscompon"
          :current-auth-user="currentAuthUser"
          :current-reservation="currentReservation"
          @submitReservationDetails="submitReservationDetails"
          @nextTab="tabIndex++"
        />
      </b-tab>

      <b-tab
        title="4. Confirmation de la réservation"
        :disabled="this.activateTabs"
      >
        <group-reservation-review
          ref="reviewcompon"
          :current-agent="currentAgent"
          :current-group-id="currentGroupId"
          :current-packages="currentPackages"
          :current-reservation="currentReservation"
          @submitReservation="submitReservation"
          :loading="is_loading"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BSkeleton,
  BCardText,
  BButton,
} from "bootstrap-vue";
import PublicGroupHeader from "@/views/public/PublicGroupHeader";
import GroupReservationPackage from "@/views/groups/group/GroupReservations/EditReservation/GroupReservationPackage.vue";
import GroupReservationTravelers from "@/views/groups/group/GroupReservations/EditReservation/GroupReservationTravelers.vue";
import GroupReservationDetails from "@/views/groups/group/GroupReservations/EditReservation/GroupReservationDetails.vue";
import GroupReservationReview from "@/views/groups/group/GroupReservations/EditReservation/GroupReservationReview.vue";
import GroupReservationPayment from "@/views/groups/group/GroupReservations/EditReservation/GroupReservationPayment.vue";

import { reactive, onMounted } from "@vue/composition-api";

import store from "@/store";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
  SET_ORG_SETTINGS_ACTION
} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";

import { GroupPackagesService } from "@/views/groups/services/GroupPackagesService.js";
import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import {useOrganizationApi} from "@/modules/authnz/composables/use-organization-api";

export const groupPackagesService = new GroupPackagesService();
export const groupReservationService = new GroupReservationService();
import mixpanel from "mixpanel-browser";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BSkeleton,
    BButton,
    GroupReservationPackage,
    GroupReservationTravelers,
    GroupReservationDetails,
    GroupReservationReview,
    GroupReservationPayment,
    PublicGroupHeader
  },
  data() {
    return {
      currentAuthUser: null,
      currentReservation: {},
      currentPackageReservation: {},
      currentPackages: [],
      unavailablePackages: [],
      currentAgent: null,
      tabIndex: 0,
      users: [],
      hasPrimary: false,
      errors: false,
      activateTabs: true,
      is_loading: false,
      saved: false,
      renderComponent: true,
      isLoadingData: true,
      displayIndex: 0,
    };
  },
  setup(props, context) {
    const {getOrganizationSettings} = useOrganizationApi();
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    const route = context.root.$route;
    const currentGroupId = route.params.id;
    const currentPackageId = route.params.package_id;
    const publicAccessToken = route.query.token;

    let tenantConfig = reactive({
      name: '',
      paymentEnabled: false,
    });

    try {
      tenantConfig.paymentEnabled =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.payment_enable;
      tenantConfig.name =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.name;
    } catch (e) {
      console.error(e);
    }

    try {
      mixpanel.track("Access group reservation", {
        group_id: currentGroupId,
        reservation_id: route.params.reservation_id,
      });
    } catch (e) {
      console.error("no mixpanel tracking");
    }

    onMounted(async() => {
      try {
        let settings = await getOrganizationSettings(publicAccessToken);
        store.dispatch(`${AUTH_STORE_NAMESPACE}/${SET_ORG_SETTINGS_ACTION}`, {settings});
      } catch (e) {
        console.error('Error: trying loading org config:', e)
        context.root.$swal({
          title: 'Réservation introuvable',
          text: "Les réservations non-complétées sont automatiquement supprimées."
            + " Utilisez le lien du groupe voyage reçu par courriel pour créer une nouvelle réservation.",
          icon: 'question',
          showCancelButton: false,
          confirmButtonText: 'OK',
          cancelButtonText: 'Rester',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
      }
    });

    return {
      displayErrorMessage,
      displaySuccessMessage,
      currentGroupId,
      currentPackageId,
      publicAccessToken,
      tenantConfig,
    };
  },
  created: function () {
    let currentGroupId = this.$route.params.id;
    let currentReservationId = this.$route.params.reservation_id;
    this.fetchCurrentReservation(currentGroupId, currentReservationId);
    this.fetchPackagesExceptSelected(currentGroupId).then((response) => {
      this.isLoadingData = false;
    });

    this.currentAuthUser =
      store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;

    this.getCurrentOwner();
  },
  methods: {
    async fetchUsers() {
      try {
        let response = await groupReservationService.getAllMembersOfOrg(this.publicAccessToken);
        this.users = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getCurrentOwner() {
      try {
        await this.fetchUsers();

        let user = this.users.find((element) => {
          return element.id === this.currentReservation.user_id;
        });

        return (this.currentAgent = user);
      } catch {
        return "N/A";
      }
    },
    fetchPackagesExceptSelected(group_id) {
      return new Promise((resolve, reject) => {
        groupPackagesService
          .getPackages(group_id, this.publicAccessToken)
          .then((response) => {
            let filteredPkg = [];
            let backorderPackages = [];

            try {
              response.data.forEach((pkg) => {
                let hasRoomsLeft = true;
                let hasTransportationLeft = true;

                pkg.rooms.forEach((room) => {
                  if (room.room_remaining_inventory.remaining_places <= 0) {
                    hasRoomsLeft = false;
                  }
                });

                if (
                  pkg.transportation.transportation_remaining_seats
                    .remaining_seats <= 0
                ) {
                  hasTransportationLeft = false;
                }

                if (hasRoomsLeft && hasTransportationLeft) {
                  filteredPkg.push(pkg);
                } else {
                  backorderPackages.push(pkg);
                }
              });

              this.currentPackages = filteredPkg;
              this.unavailablePackages = backorderPackages;
            } catch (e) {
              this.currentPackages = response.data;
            }
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchCurrentReservation() {
      let currentGroupId = this.$route.params.id;
      let currentReservationId = this.$route.params.reservation_id;
      groupReservationService
        .getReservation(
          currentGroupId,
          currentReservationId,
          this.publicAccessToken
        )
        .then((response) => {
          this.currentReservation = response.data;

          if (this.currentReservation.status == "SUBMITTED") {
            this.$nextTick(() => {

              this.tabIndex = this.tenantConfig.paymentEnabled ? 4 : 3;
            });
          }

          //this.saved=this.currentReservation.extra_information==="tempReserv";
          this.saved = this.currentReservation.status === "DRAFT";

          let lenPackageReservation =
            this.currentReservation.package_reservations.length;
          lenPackageReservation == 0
            ? (this.activateTabs = true)
            : (this.activateTabs = false);
          this.checkContainsPrimary();
          this.getCurrentOwner();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkContainsPrimary() {
      let reservationOwner = false;
      for (let i in this.currentReservation.package_reservations) {
        const element = this.currentReservation.package_reservations[i];
        reservationOwner = element.travelers.find((traveler) => {
          return traveler.reservation_owner === true;
        });

        if (reservationOwner) {
          break;
        }
      }

      if (reservationOwner) {
        this.hasPrimary = true;
      } else {
        this.hasPrimary = false;
      }
    },
    buildReservationUpdatePayload(reservation) {
      let payload = {
        insurance_contract_number: reservation.insurance_contract_number,
        insurer_name: reservation.insurer_name,
        insurance_types: reservation.insurance_types,
        has_trip_insurance: reservation.has_trip_insurance,
        has_membership: reservation.has_membership,
        extra_information: reservation.extra_information,
        wants_package_modification: reservation.wants_package_modification,
        membership_number: reservation.wants_package_modification,
        membership_company: reservation.membership_company,
        name: reservation.name,
        wants_insurance_submission: reservation.wants_insurance_submission,
        seat_selection: reservation.seat_selection,
      };

      return payload;
    },
    async submitReservationPackage(packageReservation) {
      let updatedReservation = this.buildReservationUpdatePayload(
        this.currentReservation
      );

      if (
        packageReservation.agent_assigned &&
        packageReservation.agent_assigned.update
      ) {
        updatedReservation.user_id =
          packageReservation.agent_assigned.agentProfile.id;
      }

      this.activateTabs = false;
      await groupReservationService.updateReservation(
        this.$route.params.id,
        this.$route.params.reservation_id,
        updatedReservation,
        this.publicAccessToken
      );

      for (let i = 0; i < packageReservation.rooms.length; i++) {
        await groupReservationService.postPackageReservation(
          this.$route.params.id,
          this.$route.params.reservation_id,
          packageReservation.rooms[i],
          packageReservation.selectedPackage.id,
          this.publicAccessToken
        );
      }

      this.displaySuccessMessage(
        "Vous avez ajouté " + packageReservation.rooms.length + " chambre(s)"
      );
      this.$refs.packagecompon.$refs[
        "add-reservation-package-modal"
      ].$refs.packagemodal.hide();
      this.fetchCurrentReservation();
      this.$nextTick(() => {
        this.tabIndex = 1;
      });
    },
    async submitUpdateReservationPackage(packageReservation, dofunction) {
      switch (dofunction) {
        case "packageChanged":
          let updatedReservation = this.buildReservationUpdatePayload(
            this.currentReservation
          );

          await this.updateAssignedAgent(packageReservation);

          await groupReservationService.updateReservation(
            this.$route.params.id,
            this.$route.params.reservation_id,
            updatedReservation,
            this.publicAccessToken
          );
          for (
            let i = 0;
            i < this.currentReservation.package_reservations.length;
            i++
          ) {
            await groupReservationService.deletePackageReservation(
              this.$route.params.id,
              this.$route.params.reservation_id,
              this.currentReservation.package_reservations[i].id,
              this.publicAccessToken
            );
          }

          for (let i = 0; i < packageReservation.rooms.length; i++) {
            await groupReservationService.postPackageReservation(
              this.$route.params.id,
              this.$route.params.reservation_id,
              packageReservation.rooms[i],
              packageReservation.selectedPackage.id,
              this.publicAccessToken
            );
          }

          this.displaySuccessMessage(
            "Vous avez changé " +
              packageReservation.rooms.length +
              " chambre(s)"
          );

          break;
        case "nbRoomsChangedAdd":
          for (let i = 0; i < packageReservation.rooms.length; i++) {
            if (packageReservation.rooms[i].itemStatus === "new") {
              await groupReservationService.postPackageReservation(
                this.$route.params.id,
                this.$route.params.reservation_id,
                packageReservation.rooms[i],
                this.currentReservation.package_reservations[0].group_package
                  .id,
                this.publicAccessToken
              );
              delete packageReservation.rooms[i].itemStatus;
            }
            if (packageReservation.rooms[i].itemStatus === "updated") {
              await groupReservationService.updatePackageReservation(
                this.$route.params.id,
                this.$route.params.reservation_id,
                this.currentReservation.package_reservations[i].id,
                packageReservation.selectedPackage.id,
                packageReservation.rooms[i],
                this.publicAccessToken
              );
              if (
                packageReservation.rooms[i].profileTraveler === "DELETE_ALL"
              ) {
                for (
                  let k = 0;
                  k <
                  this.currentReservation.package_reservations[i].travelers
                    .length;
                  k++
                ) {
                  await groupReservationService.deleteTraveler(
                    this.$route.params.id,
                    this.$route.params.reservation_id,
                    this.currentReservation.package_reservations[i].id,
                    this.currentReservation.package_reservations[i].travelers[k]
                      .id,
                    this.publicAccessToken
                  );
                }
              } else {
                let nbNewTraveler = this.typeToNumberOftravelers(
                  packageReservation.rooms[i].occupation.type
                );
                for (let j = 0; j < nbNewTraveler; j++) {
                  if (
                    packageReservation.rooms[i].profileTraveler !== undefined
                  ) {
                    if (
                      packageReservation.rooms[i].profileTraveler[j].deleted !==
                      1
                    ) {
                      await groupReservationService.postTravelerReservation(
                        this.$route.params.id,
                        this.$route.params.reservation_id,
                        this.currentReservation.package_reservations[i].id,
                        packageReservation.rooms[i].profileTraveler[j],
                        this.publicAccessToken
                      );
                    }
                  }
                }
              }

              delete packageReservation.rooms[i].itemStatus;
              delete packageReservation.rooms[i].profileTraveler;
            }
          }

          await this.updateAssignedAgent(packageReservation);
          this.displaySuccessMessage(
            "Vous avez changé " +
              packageReservation.rooms.length +
              " chambre(s)"
          );
          break;
        case "nbRoomsChangedDelete":
          for (let i = 0; i < packageReservation.roomToDelete.length; i++) {
            await groupReservationService.deletePackageReservation(
              this.$route.params.id,
              this.$route.params.reservation_id,
              packageReservation.roomToDelete[i].id,
              this.publicAccessToken
            );
          }
          for (let i = 0; i < packageReservation.rooms.length; i++) {
            if (packageReservation.rooms[i].itemStatus === "updated") {
              await groupReservationService.updatePackageReservation(
                this.$route.params.id,
                this.$route.params.reservation_id,
                this.currentReservation.package_reservations[i].id,
                packageReservation.selectedPackage.id,
                packageReservation.rooms[i],
                this.publicAccessToken
              );
              let nbNewTraveler = this.typeToNumberOftravelers(
                packageReservation.rooms[i].occupation.type
              );
              for (let j = 0; j < nbNewTraveler; j++) {
                if (packageReservation.rooms[i].profileTraveler !== undefined) {
                  if (
                    packageReservation.rooms[i].profileTraveler[j].deleted !== 1
                  ) {
                    await groupReservationService.postTravelerReservation(
                      this.$route.params.id,
                      this.$route.params.reservation_id,
                      this.currentReservation.package_reservations[i].id,
                      packageReservation.rooms[i].profileTraveler[j],
                      this.publicAccessToken
                    );
                  }
                }
              }

              delete packageReservation.rooms[i].itemStatus;
              delete packageReservation.rooms[i].profileTraveler;
            }
          }

          await this.updateAssignedAgent(packageReservation);
          this.displaySuccessMessage(
            "Vous avez supprimé " +
              packageReservation.roomToDelete.length +
              " chambre(s)"
          );
          break;
        case "occupationTypechanged":
          for (let i = 0; i < packageReservation.rooms.length; i++) {
            if (packageReservation.rooms[i].itemStatus === "updated") {
              await groupReservationService.updatePackageReservation(
                this.$route.params.id,
                this.$route.params.reservation_id,
                this.currentReservation.package_reservations[i].id,
                packageReservation.selectedPackage.id,
                packageReservation.rooms[i],
                this.publicAccessToken
              );
              let nbNewTraveler = packageReservation.rooms[i].travelers.length
              for (let j = 0; j < nbNewTraveler; j++) {
                if (packageReservation.rooms[i].profileTraveler !== undefined) {
                  if (
                    packageReservation.rooms[i].profileTraveler === "DELETE_ALL"
                  ) {
                    for (
                      let k = 0;
                      k <
                      this.currentReservation.package_reservations[i].travelers
                        .length;
                      k++
                    ) {
                      await groupReservationService.deleteTraveler(
                        this.$route.params.id,
                        this.$route.params.reservation_id,
                        this.currentReservation.package_reservations[i].id,
                        this.currentReservation.package_reservations[i]
                          .travelers[k].id,
                        this.publicAccessToken
                      );
                    }
                  }
                  if (
                    packageReservation.rooms[i].profileTraveler[j].deleted === 1
                  ) {
                    await groupReservationService.deleteTraveler(
                      this.$route.params.id,
                      this.$route.params.reservation_id,
                      this.currentReservation.package_reservations[i].id,
                      packageReservation.rooms[i].profileTraveler[j].id,
                      this.publicAccessToken
                    );
                  }

                  await groupReservationService.postTravelerReservation(
                    this.$route.params.id,
                    this.$route.params.reservation_id,
                    this.currentReservation.package_reservations[i].id,
                    packageReservation.rooms[i].profileTraveler[j],
                    this.publicAccessToken
                  );
                }
              }

              delete packageReservation.rooms[i].itemStatus;
              delete packageReservation.rooms[i].profileTraveler;
            }
          }

          await this.updateAssignedAgent(packageReservation);
          this.displaySuccessMessage(
            "Vous avez changé " +
              packageReservation.rooms.length +
              " chambre(s)"
          );
          break;
        case "agentChanged":
          await this.updateAssignedAgent(packageReservation);
          this.displaySuccessMessage(
            "Vous avez changé le conseiller de cette réservation"
          );
          break;
        default:
          console.warn("nothing");
      }
      this.refreshReservationData();
    },
    async updateAssignedAgent(reservation) {
      if (
        reservation.agent_assigned === undefined ||
        !reservation.agent_assigned.update
      ) {
        return;
      }
      let updatedReservation = this.buildReservationUpdatePayload(
        this.currentReservation
      );
      updatedReservation.user_id = reservation.agent_assigned.agentProfile.id;
      await groupReservationService.updateReservation(
        this.$route.params.id,
        this.$route.params.reservation_id,
        updatedReservation,
        this.publicAccessToken
      );
    },
    refreshReservationData() {
      this.$refs.packagecompon.$refs[
        "add-reservation-package-modal"
      ].$refs.packagemodal.hide();
      this.fetchCurrentReservation();
      this.getCurrentOwner();
      this.$refs.packagecompon.$forceUpdate();
      this.$refs.packagecompon.$refs[
        "add-reservation-package-modal"
      ].$forceUpdate();
    },
    submitReservationAll(reservation) {
      this.is_loading = true;
      groupReservationService
        .postReservationAll(
          this.$route.params.id,
          reservation,
          this.publicAccessToken
        )
        .then((response) => {
          let match = response.headers.location.split("/");
          this.$router.push({
            name: "group-edit-reservation",
            params: { reservation_id: match[7] },
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    deleteTravelerAll(traveler, reservation_package_id) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Voulez-vous supprimer ce voyageur? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Garder",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          groupReservationService
            .deleteTraveler(
              this.$route.params.id,
              this.$route.params.reservation_id,
              reservation_package_id,
              traveler.id,
              this.publicAccessToken
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Supprimé!",
                text: "Le voyageur a été supprimé.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.fetchCurrentReservation();
            });
        } else {
          console.error('unable to delete traveler', result)
        }
      });
    },
    postTravelerReservationPackage(traveler, reservation_package_id) {
      groupReservationService
        .postTravelerReservation(
          this.$route.params.id,
          this.$route.params.reservation_id,
          reservation_package_id,
          traveler,
          this.publicAccessToken
        )
        .then((response) => {
          this.fetchCurrentReservation();
          this.$refs.groupTravelers.$forceUpdate();
          this.displaySuccessMessage("Vous avez ajouté un voyageur");
        });
    },
    updateTravelerReservationPackage(traveler, reservation_package_id) {
      groupReservationService
        .updateTravelerReservation(
          this.$route.params.id,
          this.$route.params.reservation_id,
          reservation_package_id,
          traveler.id,
          traveler,
          this.publicAccessToken
        )
        .then((response) => {
          this.fetchCurrentReservation();
          this.$refs.groupTravelers.$forceUpdate();
          this.displaySuccessMessage("Vous avez modifié un voyageur");
        });
    },
    async updateRoomPreferences(roomPref) {
      try {
        let response = await groupReservationService.updatePackageReservation(
          this.$route.params.id,
          this.$route.params.reservation_id,
          roomPref.id,
          roomPref.package_id,
          roomPref,
          this.publicAccessToken
        );
        this.$refs.groupTravelers.$forceUpdate();
        this.displaySuccessMessage("Chambre modifiée");
      } catch (e) {
        console.error(e);
      }
    },
    submitReservationDetails(reservation) {
      let reservationDetails = this.buildReservationUpdatePayload(reservation);
      groupReservationService
        .updateReservation(
          this.$route.params.id,
          this.$route.params.reservation_id,
          reservationDetails,
          this.publicAccessToken
        )
        .then((response) => {
          this.fetchCurrentReservation();
          this.$forceUpdate();
          this.displaySuccessMessage(
            "Vous avez bien modifié cette réservation"
          );

          this.$nextTick(() => {
            this.tabIndex = 3;
          });
        });
    },
    typeToNumberOftravelers(type) {
      const types = {
        SINGLE: 1,
        DOUBLE: 2,
        TRIPLE: 3,
        QUADRUPLE: 4,
      };
      return types[type];
    },
    submitReservation() {
      let i = 0;
      let reservation_packages = this.currentReservation.package_reservations;
      for (let counter = 0; counter < reservation_packages.length; counter++) {
        let reservation_package = reservation_packages[counter];
        if (
          reservation_package.travelers.filter(x => x.type == 'ADULT').length !=
          this.typeToNumberOftravelers(reservation_package.occupation_type.type)
        ) {
          i++;
        }
      }
      if (i > 0) {
        this.displayErrorMessage(
          "Vous devez remplir toutes les informations concernant les voyageurs"
        );
        this.errors = true;
        this.tabIndex = 1;
      } else {
        let statusObj = {
          status: "SUBMITTED",
        };

        if (this.tenantConfig.paymentEnabled) {
          this.is_loading = true;
          groupReservationService.updateStatus(this.$route.params.id, this.$route.params.reservation_id,statusObj, this.publicAccessToken).then(response => {
            this.saved = false;
            this.displaySuccessMessage(
              "Vous avez bien soumis cette réservation"
            );

            this.$refs["reviewcompon"].pay().then((error) => {
              if (error) {
                console.error(error.type, error.message);
                this.displayErrorMessage(
                  "Impossible de compléter la réservation",
                  error.message
                );
              }
            });

          }).catch((e) => {
            console.error(e);
            let errorMessage =
              "Une erreur s'est produite lors de la finalisation de la réservation.";

            if (
              e.data &&
              e.data.payload &&
              e.data.payload &&
              e.data.payload.message.includes("below zero")
            ) {
              errorMessage +=
                " L'inventaire d'un des forfaits sélectionnés est insuffisant.";
            }
            this.displayErrorMessage(
              "Impossible de compléter la réservation",
              errorMessage
            );
          })
          .finally(() => {
            this.is_loading = false;
          });
        } else {
          this.is_loading = true;
          groupReservationService
            .updateStatus(
              this.$route.params.id,
              this.$route.params.reservation_id,
              statusObj,
              this.publicAccessToken
            )
            .then((response) => {
              this.saved = false;
              this.displaySuccessMessage(
                "Vous avez bien soumis cette réservation"
              );
              let formattedHTML = `<span>Une confirmation vous a été envoyé par courriel.</span><br><br><span>Votre numéro de réservation: ${this.currentReservation.reservation_number}</span>`;
              this.$swal({
                icon: "success",
                title: "Complétée!",
                html: formattedHTML,
                text: "Vous avez bien soumis cette réservation. Votre numéro de réservation est ",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });


              this.fetchCurrentReservation();
              this.$forceUpdate();
            })
            .catch((e) => {
              console.error(e);
              let errorMessage =
                "Une erreur s'est produite lors de la finalisation de la réservation.";
              if (
                e.data &&
                e.data.payload &&
                e.data.payload &&
                e.data.payload.message.includes("below zero")
              ) {
                errorMessage +=
                  " L'inventaire d'un des forfaits sélectionnés est insuffisant.";
              }
              this.displayErrorMessage(
                "Impossible de compléter la réservation",
                errorMessage
              );
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      }
    },
  },
};
</script>
