<template>
  <b-modal
    id="modal-select2"
    title="Forfait & chambre"
    ok-title="Enregistrer"
    cancel-title="Annuler"
    ref="packagemodal"
    cancel-variant="outline-secondary"
    size="lg"
    @cancel="refreshReservation"
    @ok.prevent="submitReservationPackage"
    @close="$emit('cancel')"
    :ok-disabled="canDelete"
    no-close-on-backdrop
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row v-if="currentUser.role.role === 'organization_admin'">
          <b-col md="12">
            <h1 class="section-label mx-0 mb-2">
              Conseiller réservation
            </h1>
          </b-col>
          <b-col md="6" >
            <validation-provider
              #default="{ errors }"
              name="Nom de forfait"
              rules="required"
             >
              <b-form-group
                label="Associer un conseiller à cette réservation"
                label-for="selected-package"
              >
                <div v-if="agents && currentAgent">
                <v-select v-if="agents && currentAgent"
                          :options="agents"  label=""
                          v-model="selectedAgent"
                          :get-option-label="(option) => option.full_name"
                          @input="assignAgent"
                >
                  <template #option="{ agency,full_name,role }" >
                    {{ full_name }}
                    <br />
                    <cite v-if="role!=='organization_admin'">
                      <span v-if="agency">{{agency.name}}</span>, <span :class="role==='agency_admin'?'text-danger':'text-success'" >{{role==='agency_admin'?"Admin":"Agent"}}</span>
                    </cite>
                    <cite v-else>
                      Organisation, <span class="text-danger" >Admin</span>
                    </cite>
                  </template>

                </v-select>
                </div>
                <div v-else>
                 <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>

                    <span style="padding-left:10px"><i>Chargement des agents</i></span>

                </div>

              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h1 class="section-label mx-0 mb-2">
              Paramètres du forfait
            </h1>
          </b-col>

        </b-row>


        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Nom de forfait"
              rules="required"
            >
              <b-form-group
                label="Sélection du forfait"
                label-for="selected-package"
              >

                <v-select
                  id="group-type"
                  :options="currentPackages"
                  v-model=reservation.selectedPackage
                  @input="refreshOccupType"
                  label="name"

                  :reduce="option => option"
                  required
                />

              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Nombre de chambres"
              rules="required"
            >
              <b-form-group
                label="Indiquer le nombre de chambres"
                label-for="selected-package"
              >
                <v-select
                  id="group-type"
                  :options="availableNumberOfRooms"
                  :disabled="canDelete"
                  ref="vSelect"
                  style="width:50%"
                  @option:selected="setRooms($event)"
                  v-model=reservation.nbRooms
                  required
                />

              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

        </b-row>
        <div v-for="(room,index) in reservation.rooms" :key="index">
          <b-row v-if="index==0">
            <b-col md="12" class="mt-1">
              <h1 class="section-label mx-0 mb-2">
                Configuration des chambres
              </h1>
            </b-col>

          </b-row>
          <b-row>
            <b-col md="12">
              <h6 class="text-primary mx-0 mb-1">
                Chambre #{{ index + 1 }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Type d'occupation"
                rules="required"
              >
                <b-form-group
                  label="Occupation"
                  label-for="selected-package"
                >

                  <v-select
                    id="group-type"
                    :options="reservation.selectedPackage.occupation_types"
                    v-model=room.occupation
                    label="type"
                    :reduce="option => option"
                    @option:selected="setTravelersInRoom(index)"
                  />


                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6" v-if="canDelete">
              <div class="d-flex" style="margin-top: 27px;">
                <div>
                  <span class="badge badge-success" v-if="countTravelerFilledInReservation(index)>0">{{countTravelerFilledInReservation(index)+" voyageur(s) inscrits"}}
                  </span>
                  <span class="badge badge-secondary" v-else>{{countTravelerFilledInReservation(index)+" voyageur(s) inscrits"}}
                  </span>
                </div>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  v-if="canDelete"
                  style="margin-top: -7px;margin-left: 13px;"
                  @click="deleteOccupType(index)"
                >
                  <feather-icon icon="Trash2Icon"/>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!isTransat">
            <b-col md="12" v-if="room && room.occupation && room.occupation.type">
              <b-row>
                <b-col v-for="(index) in typeToNumberTravelers(room.occupation.type)" md="4" :key="index">
                  <b-form-group
                    
                    :label="`Voyageur #${index}`"
                    label-for="selected-package"
                  >

                    <v-select
                      v-if="room.travelers[index-1] == 'adult'"
                      class="style-chooser"
                      id="group-type"
                      :clearable="false"
                      :disabled="true"
                      :searchable="false"
                      value="Adulte"
                    />
                  </b-form-group>
                </b-col>
                <template v-if="room.occupation.supported_children_types.length > 0">
                  <b-col v-for="(index) in (room.occupation.travelers_max - typeToNumberTravelers(room.occupation.type))" md="4" :key="index">
                    <b-form-group
                      
                      :label="`Voyageur #${typeToNumberTravelers(room.occupation.type) + index} (optionnel)`"
                      label-for="selected-package"
                    >
                      <v-select
                        class="style-chooser"
                        id="group-type"
                        :clearable="false"
                        :disabled="false"
                        :searchable="false"
                        :options="getChildrenCategories(room)"
                        label="text"
                        value="Enfant"
                      />
                      <!-- <v-select
                        v-if="room.travelers[index-1] != 'adult' && room.occupation.supported_children_types.length > 0"
                        id="group-type"
                        :options="room.occupation.supported_children_types"
                        label="text"
                        :clearable="false"
                        :disabled="selectedPackage.acceptChildren"
                        v-model="room.travelers[index-1]"
                        @option:selected="refreshField()"
                        @input="room.itemStatus = 'updated'"
                      /> -->
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>
        </div>

      </b-form>
      <list-traveler @UpdateTravelers="UpdateTravelers" ref="traveler-list" :room-index="roomIndex"
                     :list-traveler="travelersToConserve" :nb-delete="countDeleteTraveler"></list-traveler>
    </validation-observer>
  </b-modal>
</template>

<script>
  import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
  import BCardCode from '@core/components/b-card-code'
  import {
    BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText, BRow, BCol, BCard, BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormRadio,
    BFormTextarea,
    BTabs,
    BFormFile,
    BFormCheckboxGroup,
    BTab,
    BFormInvalidFeedback,
    BAlert

  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import groupCreationConfig from '@core/data/groupCreationConfig.js'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Vue from 'vue'
  import {ValidationObserver, ValidationProvider} from "vee-validate";
  import {ref} from "@vue/composition-api/dist/vue-composition-api";
  import {useToastNotification} from "@/shared/composables/use-toast-notifications";
  import ListTraveler from '@/views/groups/group/GroupReservations/EditReservation/ListTraveler'
  import {groupReservationService} from "@/views/groups/group/GroupReservations/EditReservation/GroupReservation";
  import Tenant from "@/plugins/tenant";

  export default {
    components: {
      BCardCode,
      BButton,
      BModal,
      BForm,
      BFormInput,
      BCardText,
      BFormGroup,
      vSelect,
      BRow,
      BCol,
      BInputGroup,
      BFormCheckbox,
      BFormCheckboxGroup,
      BInputGroupPrepend,
      BFormTextarea,
      BTabs,
      BFormFile,
      BTab,
      BFormInvalidFeedback,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      BFormRadio,
      ListTraveler,
      BAlert
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },

    props: ['currentPackages', 'currentReservation','agents','currentAgent','unavailablePackages'],
    setup() {
      const {
        displaySuccessMessage,
        displayErrorMessage,
        displayWarningMessage,
        displayInfoMessage
      } = useToastNotification();

      let isTransat = ref(true);

      const tenantId = Tenant.resolveTenantId();

      if (tenantId === "transat") {
        isTransat.value = true;
      }

      
      return {
        displayErrorMessage,
        displaySuccessMessage,
        displayWarningMessage,
        displayInfoMessage,
        isTransat
      }

    },
    data() {
      let selectedPackage = {};
      let nbOfRooms = 0;
      if (this.currentReservation
        && this.currentReservation.package_reservations
        && this.currentReservation.package_reservations.length > 0
      ) {
        nbOfRooms = this.currentReservation.package_reservations.length;
        selectedPackage = this.currentReservation.package_reservations[0].group_package;
      } else if (this.$route.query.package_id) {
        let packageFound = this.currentPackages.find(x => {
          return x.id === this.$route.query.package_id;
        });
        if (packageFound) {
          selectedPackage = packageFound;
        }
      }

      return {
        editAgent:false,
        hasGuide: false,
        groupCreationConfig,
        oldNbRooms: 0,
        canDelete: false,
        countDelete: 0,
        selectedAgent: null,
        countDeleteTraveler: 0,
        deletedRooms: [],
        addedRooms: [],
        travelersToConserve: null,
        roomIndex: null,
        updateWithAdd: false,
        updateWithDelete: false,
        reservation: {
          nbRooms: nbOfRooms,
          selectedPackage: selectedPackage,
          rooms: [],

        },
        childrenCategories: [
          {text: "Aucun", value: null},
          {text: "Bébé moins de 2 ans", value: "BABY_TWO_YEARS_OR_LESS"},
          {text: "Bébé 2 ans", value: "BABY_TWO_YEARS"},
          {text: "Enfant 2 à 12 ans", value: "CHILD_TWO_TO_TWELVE"},
          {text: "Enfant 3 à 11 ans", value: "CHILD_THREE_TO_ELEVEN"},
          {text: "Enfant 3 à 12 ans", value: "CHILD_THREE_TO_TWELVE"},
          {text: "Enfant 2 à 5 ans", value: "CHILD_TWO_TO_FIVE"},
          {text: "Enfant 5 à 12 ans", value: "CHILD_FIVE_TO_TWELVE"},
          {text: "Ado 13 à 17 ans", value: "TEEN_THIRTEEN_TO_SEVENTEEN"}],
        rooms: [],
        currentreservationTemp: {},
      }
    },
    created() {
      localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.nbRooms)
      this.$nextTick(function() {
        this.selectedAgent = this.currentAgent
      })
      this.checkReservation();
    },
    methods: {
      assignAgent(){
        this.reservation.agent_assigned={
          update: this.currentAgent !== this.selectedAgent,
          agentProfile: this.selectedAgent
        }
      },
      editAgentFn(){
        this.editAgent=true;
      },
      arrLength(ar) {
        let length = 0;
        if (ar!==undefined){
          while (ar[length] !== undefined) {
            length++;
          }
        }

        return length;
      },
      checkReservation() {
        if (this.reservation.nbRooms > 0) {
          localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
          localStorage.setItem('firstLoadedRoomnb' + this.$route.params.reservation_id, this.reservation.nbRooms)
        }
        let pack_resers = this.currentReservation.package_reservations?.length;
        if (pack_resers > 0) {
          for (let i = 0; i < pack_resers; i++) {
            this.reservation.rooms.push({
              occupation: this.currentReservation.package_reservations[i].occupation_type,
              id: this.currentReservation.package_reservations[i].id,
              nbTraveler: this.currentReservation.package_reservations[i].travelers.length
            })
            this.setTravelersInRoom(i)
          }

        } else {
          console.debug('not set')
        }
      },
      setRooms() {
        const NO_PREFERENCE = "no_preference";
        let previousNbRooms = localStorage.getItem('oldNbRoom' + this.$route.params.reservation_id);
        if (!previousNbRooms) {
          previousNbRooms = 0;
        }

        if (this.currentReservation.package_reservations.length > 0) {
          if (this.currentReservation.package_reservations[0].group_package.id === this.reservation.selectedPackage.id) {
            
            if (this.reservation.nbRooms > previousNbRooms) {
              let newRoom = this.reservation.nbRooms - previousNbRooms;
              for (let i = 0; i < newRoom; i++) {
                this.reservation.rooms.push(
                  {
                    travelers: [],
                    preferences: {
                      bed_option: NO_PREFERENCE,
                      floor_preference: NO_PREFERENCE,
                      options: [],
                      occupation: {
                        type: 'ADULT'
                      }
                    },
                    itemStatus: "new",
                  }
                )
              }

              localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
              this.updateWithDelete = false;
              this.updateWithAdd = true;
            }
            else if (this.reservation.nbRooms < previousNbRooms) {
              if (this.checkIfTravelersFilled()) {
                this.$swal({
                  title: 'Modification du nombre de chambres',
                  text: "Vous avez diminué le nombre de chambres. Voulez-vous choisir la ou les chambres à supprimer?",
                  icon: 'warning',
                  allowOutsideClick: false,
                  showCancelButton: true,
                  confirmButtonText: 'Oui',
                  cancelButtonText: 'Non, suppression automatique',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.canDelete = true;
                      this.countDelete = previousNbRooms - this.reservation.nbRooms;
                      localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
                      this.updateWithAdd = false;
                      this.updateWithDelete = true;

                    } else {


                      let newRoom = previousNbRooms - this.reservation.nbRooms;
                      this.reservation.rooms.reverse();
                      this.deletedRooms = [];
                      for (let j = 0; j < newRoom; j++) {
                        this.deletedRooms.push(this.reservation.rooms[j])
                      }
                      this.reservation.rooms.splice(0, newRoom);
                      this.reservation.rooms.reverse();
                      localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
                      this.reservation.roomToDelete = this.deletedRooms;
                      this.updateWithAdd = false;
                      this.updateWithDelete = true;
                    }
                  })
              } else {
                let newRoom = previousNbRooms - this.reservation.nbRooms;
                this.reservation.rooms.reverse();
                this.deletedRooms = [];
                for (let j = 0; j < newRoom; j++) {
                  this.deletedRooms.push(this.reservation.rooms[j])
                }
                this.reservation.rooms.splice(0, newRoom);
                this.reservation.rooms.reverse();

                localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
                this.reservation.roomToDelete = this.deletedRooms;
                this.updateWithAdd = false;
                this.updateWithDelete = true;
              }

            }
          }
          else {
            let nbRooms = previousNbRooms;
            if (this.reservation.rooms.length > 0) {
              if (this.reservation.nbRooms > nbRooms) {
                nbRooms = this.reservation.nbRooms - previousNbRooms
                for (let i = 0; i < nbRooms; i++) {

                  this.reservation.rooms.push(
                    {
                      travelers: [],
                      preferences: {
                        bed_option: NO_PREFERENCE,
                        floor_preference: NO_PREFERENCE,
                        options: []
                      }
                    }
                  )

                }
                localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
              }
              if (this.reservation.nbRooms < nbRooms) {
                nbRooms = previousNbRooms - this.reservation.nbRooms
                this.reservation.rooms.reverse();
                this.reservation.rooms.splice(0, nbRooms);
                this.reservation.rooms.reverse();
                this.deletedRooms.push(nbRooms)
                localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
                this.updateWithAdd = false;
                this.updateWithDelete = true;
              }

            } else {
              for (let i = 0; i < this.reservation.nbRooms; i++) {

                this.reservation.rooms.push(
                  {
                    travelers: [],
                    preferences: {
                      bed_option: NO_PREFERENCE,
                      floor_preference: NO_PREFERENCE,
                      options: []
                    }
                  }
                )

              }
              localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
            }
          }
        }
        else {

          let nbRooms = previousNbRooms;
          if (this.reservation.rooms.length > 0) {
            if (this.reservation.nbRooms > nbRooms) {
              nbRooms = this.reservation.nbRooms - previousNbRooms
              for (let i = 0; i < nbRooms; i++) {

                this.reservation.rooms.push(
                  {
                    travelers: [],
                    preferences: {
                      bed_option: NO_PREFERENCE,
                      floor_preference: NO_PREFERENCE,
                      options: []
                    }
                  }
                )

              }
              localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
            }
            if (this.reservation.nbRooms < nbRooms) {
              nbRooms = previousNbRooms - this.reservation.nbRooms
              this.reservation.rooms.reverse();
              this.reservation.rooms.splice(0, nbRooms);
              this.reservation.rooms.reverse();
              this.deletedRooms.push(nbRooms)
              localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
            }

          } else {
            for (let i = 0; i < this.reservation.nbRooms; i++) {

              this.reservation.rooms.push(
                {
                  travelers: [],
                  preferences: {
                    bed_option: NO_PREFERENCE,
                    floor_preference: NO_PREFERENCE,
                    options: []
                  }
                }
              )

            }
            localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
          }

        }

        const nbRoomsElement = this.$refs.vSelect.searchEl;
        if (nbRoomsElement) {
          nbRoomsElement.select();
        }
      },
      setTravelersInRoom(index) {
        const ADULT = 'adult'
        const SINGLE = 'SINGLE'
        const DOUBLE = 'DOUBLE'
        const TRIPLE = 'TRIPLE'
        const QUADRUPLE = 'QUADRUPLE'

        const UNKNOWN = null;

        let occupation_type = this.reservation.rooms[index].occupation.type;

        if (occupation_type == SINGLE) {
          this.reservation.rooms[index].travelers = [ADULT, UNKNOWN, UNKNOWN, UNKNOWN]

        } else if (occupation_type == DOUBLE) {
          this.reservation.rooms[index].travelers = [ADULT, ADULT, UNKNOWN, UNKNOWN]
        } else if (occupation_type == TRIPLE) {
          this.reservation.rooms[index].travelers = [ADULT, ADULT, ADULT, UNKNOWN]
        } else if (occupation_type == QUADRUPLE) {
          this.reservation.rooms[index].travelers = [ADULT, ADULT, ADULT, ADULT]
        }
        if (this.currentReservation.package_reservations.length > 0 && this.reservation.selectedPackage.id === this.currentReservation.package_reservations[0].group_package.id) {
          if (this.currentReservation.package_reservations[index] !== undefined) {
            if (this.reservation.rooms[index].occupation.id !== this.currentReservation.package_reservations[index].occupation_type.id) {
              if (this.checkIfTypeOccupLessOrUp(this.currentReservation.package_reservations[index].occupation_type, this.reservation.rooms[index].occupation).response) {
                if (this.currentReservation.package_reservations[index].travelers.length > 0) {
                  if (this.checkIfTypeOccupLessOrUp(this.currentReservation.package_reservations[index].occupation_type, this.reservation.rooms[index].occupation).nbTraveler <= this.currentReservation.package_reservations[index].travelers.length) {
                    this.$swal({
                      title: 'Cette chambre contient des voyageurs inscrits',
                      text: "Voulez-vous choisir le(s) voyageur(s) à supprimer? ",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Oui',
                      cancelButtonText: 'Non, supprimer tout',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {
                        this.travelersToConserve = this.currentReservation.package_reservations[index].travelers;
                        this.roomIndex = index;
                        this.countDeleteTraveler = this.currentReservation.package_reservations[index].travelers.length - this.typeToNumberTravelers(this.reservation.rooms[index].occupation.type)
                        this.$refs['traveler-list'].$children[0].show()
                      } else {
                        this.reservation.rooms[index].itemStatus = "updated";
                        this.currentReservation.package_reservations[index].travelers.forEach(element => {
                          element.deleted = 1;
                        })
                        this.reservation.rooms[index].profileTraveler = "DELETE_ALL"
                      }
                    })

                  } else {
                    this.reservation.rooms[index].itemStatus = "updated";
                  }
                } else {
                  this.reservation.rooms[index].itemStatus = "updated";
                }
              } else {
                this.reservation.rooms[index].itemStatus = "updated";
              }
            }
          }

        }
        this.refreshField();
      },
      checkIfTypeOccupLessOrUp(currentOccupType, selectedOccupType) {
        let nbCurrentType = this.typeToNumberTravelers(currentOccupType.type);
        let nbSelectedType = this.typeToNumberTravelers(selectedOccupType.type);
        if (nbSelectedType < nbCurrentType) {
          return {response: true, nbTraveler: nbCurrentType - nbSelectedType}
        } else {
          return {response: false}
        }

      },
      setTravelersInRoomArray(reservation, index) {
        const ADULT = 'adult'
        const SINGLE = 'SINGLE'
        const DOUBLE = 'DOUBLE'
        const TRIPLE = 'TRIPLE'
        const QUADRUPLE = 'QUADRUPLE'

        const UNKNOWN = null;

        let occupation_type = reservation.rooms[index].occupation.type;

        if (occupation_type == SINGLE) {
          return reservation.rooms[index].travelers = [ADULT, UNKNOWN, UNKNOWN, UNKNOWN]

        } else if (occupation_type == DOUBLE) {
          return reservation.rooms[index].travelers = [ADULT, ADULT, UNKNOWN, UNKNOWN]
        } else if (occupation_type == TRIPLE) {
          return reservation.rooms[index].travelers = [ADULT, ADULT, ADULT, UNKNOWN]
        } else if (occupation_type == QUADRUPLE) {
          return reservation.rooms[index].travelers = [ADULT, ADULT, ADULT, ADULT]
        }


      },
      refreshField() {
        this.$forceUpdate();
      },
      refreshOccupType() {
        this.reservation.rooms = [];
        this.reservation.nbRooms = 0;
      },
      checkIfTravelersFilled() {
        let countTraveler = 0;
        this.currentReservation.package_reservations.forEach(element => {
          if (element.travelers.length > 0) {
            countTraveler++;
          }
        });
        if (countTraveler > 0) {
          return true
        } else {
          return false;
        }
      },
      countTravelerFilledInReservation(index) {
        if (this.reservation.rooms[index].nbTraveler !== undefined) {
          return this.reservation.rooms[index].nbTraveler
        }

      },
      deleteOccupType(index) {
        this.deletedRooms.push(this.reservation.rooms[index]);
        this.reservation.rooms.splice(index, 1);
        this.countDelete--;
        this.reservation.roomToDelete = this.deletedRooms;

        if (this.countDelete <= 0) {
          this.canDelete = false
        }
      },
      submitReservationPackage() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            if (this.currentReservation.package_reservations.length > 0) {

              this.updateReservationPackage()
            } else {
              localStorage.setItem('firstLoadedRoomnb' + this.$route.params.reservation_id, this.reservation.nbRooms)
              this.$emit('submitReservationPackage', this.reservation)
            }
          }
        })
      },
      updateReservationPackage() {
        this.currentreservationTemp.nbRooms = this.reservation.nbRooms;
        this.currentreservationTemp.rooms = [];
        this.currentreservationTemp.selectedPackage = this.reservation.selectedPackage;

        let currentNbTravelers = 0
        let previousNbTravelers = 0;
        // let roomTravelersUpdated = false;

        // for (let i = 0; i < this.currentreservationTemp.nbRooms; i++) {
        //   for (let j = 0; j < this.currentReservation.nbRooms; j++) {
        //     this.reservation.rooms[i].id
        //   }
        // }

        for (let i = 0; i < this.currentreservationTemp.nbRooms; i++) {
          this.currentreservationTemp.rooms.push(this.reservation.rooms[i]);
          currentNbTravelers += this.reservation.rooms[i].travelers.length;
        }

        for (let i = 0; i < this.currentReservation.nbRooms; i++) {
          previousNbTravelers += this.reservation.rooms[i].travelers.length;
        }

        if (this.reservation.selectedPackage.id !== this.currentReservation.package_reservations[0].group_package.id) {
          this.$swal({
            title: 'Modification du forfait',
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: 'warning',

            showCancelButton: true,
            confirmButtonText: 'Oui, modifier!',
            cancelButtonText: 'Annuler',
            allowOutsideClick: false,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$emit('submitUpdateReservationPackage', this.reservation, 'packageChanged')
            }else {
              this.$refs['packagemodal'].hide()
              this.refreshReservation()
            }
          })
        }
        else if (this.currentreservationTemp.nbRooms !== this.currentReservation.package_reservations.length) {
          if (this.updateWithAdd) {
            this.$swal({
              title: 'Modification du nombre de chambres',
              text: "Vous avez augmenté le nombre de chambres.",
              icon: 'warning',
              allowOutsideClick: false,
              showCancelButton: true,
              confirmButtonText: 'Valider',
              cancelButtonText: 'Annuler',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value) {
                  this.$emit('submitUpdateReservationPackage', this.reservation, 'nbRoomsChangedAdd')
                  localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
                } else {
                  this.$refs['packagemodal'].hide()
                  this.$refs['packagemodal'].$forceUpdate();
                  this.$forceUpdate();
                  let i = this.reservation.rooms.length
                  while (i--) {
                    if (this.reservation.rooms[i].newItem === 1) {
                      this.reservation.rooms.splice(i, 1);
                    }
                  }
                  this.reservation.nbRooms = localStorage.getItem('firstLoadedRoomnb' + this.$route.params.reservation_id)
                  localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
                  localStorage.setItem('firstLoadedRoomnb' + this.$route.params.reservation_id, localStorage.getItem('oldNbRoom'))
                  this.updateWithAdd = false;
                  this.$refs['packagemodal'].hide()
                  this.refreshReservation()
                }
              })
          }
          if (this.updateWithDelete) {
            this.$swal({
              title: 'Modification du nombre de chambres',
              text: "Vous avez diminué le nombre de chambres.",
              icon: 'warning',
              allowOutsideClick: false,
              showCancelButton: true,
              confirmButtonText: 'Valider',
              cancelButtonText: 'Annuler',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value) {
                  this.$emit('submitUpdateReservationPackage', this.reservation, 'nbRoomsChangedDelete')
                  localStorage.setItem('oldNbRoom' + this.$route.params.reservation_id, this.reservation.nbRooms)
                } else {
                  this.$refs['packagemodal'].hide()
                  this.$refs['packagemodal'].$forceUpdate();
                  this.$forceUpdate();
                  this.updateWithDelete = false;
                  this.deletedRooms = [];
                  this.refreshReservation()
                }
              })
          }
        }
        else if (this.currentreservationTemp.rooms.length === this.currentReservation.package_reservations.length) {
          let changedOccup = [];
          this.currentReservation.package_reservations.forEach((occup_typeCurrent, index) => {
            const occup_typeNew = this.currentreservationTemp.rooms[index];
            if (occup_typeCurrent.occupation_type.id !== occup_typeNew.occupation.id) {
              changedOccup.push({old: occup_typeCurrent.occupation_type.id, new: occup_typeNew.occupation})
            }
          });
          if (changedOccup.length > 0 || previousNbTravelers != currentNbTravelers) {
            this.$swal({
              title: 'Modification du type d\'occupation de chambre',
              text: "Vous avez modifié un type d'occupation",
              icon: 'warning',
              allowOutsideClick: false,
              showCancelButton: true,
              confirmButtonText: 'Valider',
              cancelButtonText: 'Annuler',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(result => {

                if (result.value) {
                  this.$emit('submitUpdateReservationPackage', this.reservation, 'occupationTypechanged')
                }else {
                  this.$refs['packagemodal'].hide()
                  this.refreshReservation()
                }
              })
          }
          else if (this.selectedAgent && this.currentAgent.id!==this.selectedAgent.id){
            this.$swal({
              title: 'Modification de conseiller',
              text: "Vous avez modifié un conseiller pour cette réservation",
              icon: 'warning',
              allowOutsideClick: false,
              showCancelButton: true,
              confirmButtonText: 'Valider',
              cancelButtonText: 'Annuler',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(result => {

                if (result.value) {
                  this.$emit('submitUpdateReservationPackage', this.reservation, 'agentChanged')
                }else {
                  this.$refs['packagemodal'].hide()
                  this.refreshReservation()
                }
              })
          }
        }

      },
      UpdateTravelers(travelers, roomId) {
        this.reservation.rooms[roomId].itemStatus = "updated";
        this.reservation.rooms[roomId].profileTraveler = travelers;
        this.$refs['traveler-list'].$refs.listtraveler.hide()
      },
      refreshReservation(){
        this.reservation = {

          nbRooms: 0,
          selectedPackage: null,
          rooms: [],
        };
        this.reservation.nbRooms = this.currentReservation.package_reservations.length;
        this.reservation.selectedPackage = this.currentReservation.package_reservations[0].group_package;
        if (this.currentReservation.package_reservations.length > 0) {

          let pack_resers = this.currentReservation.package_reservations.length;
          if (pack_resers > 0) {
            for (let i = 0; i < pack_resers; i++) {
              this.reservation.rooms.push({
                occupation: this.currentReservation.package_reservations[i].occupation_type,
                id: this.currentReservation.package_reservations[i].id,
                nbTraveler: this.currentReservation.package_reservations[i].travelers.length
              })
              this.setTravelersInRoom(i)
            }

          }
        }
      },
      getChildrenCategories(room) {
        return this.childrenCategories.filter(x => {
          return room.occupation.supported_children_types?.includes(x.value)
        })
      }
    },
    computed: {
      typeToNumberTravelers() {
        const types = {
          SINGLE: 1,
          DOUBLE: 2,
          TRIPLE: 3,
          QUADRUPLE: 4,
        }
        return type => types[type];
      },
      availableNumberOfRooms() {
        if (this.$tenant() === 'transat' || this.$tenant() === 'groupx') {
          return [1];
        }
        return [1,2,3,4,5,6,7,8,9];
      },
      getOccupationPerPackage() {
        return
      },
      occupationType() {
        return this.reservation.rooms;
      },
      nbRooms() {
        return this.reservation.nbRooms;
      },
      selectedPackage() {
        return this.reservation.selectedPackage;
      },
      currentUser() {
        return this.$store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];
      },
    },
    watch: {
      currentReservation: function (newVal, oldVal) { // watch it
        this.reservation = {

          nbRooms: 0,
          selectedPackage: null,
          rooms: [],
        };
        this.reservation.nbRooms = newVal.package_reservations.length;
        this.reservation.selectedPackage = newVal.package_reservations[0].group_package;
        if (newVal.package_reservations.length > 0) {

          let pack_resers = newVal.package_reservations.length;
          if (pack_resers > 0) {
            for (let i = 0; i < pack_resers; i++) {
              this.reservation.rooms.push({
                occupation: newVal.package_reservations[i].occupation_type,
                id: newVal.package_reservations[i].id,
                nbTraveler: newVal.package_reservations[i].travelers.length
              })
              this.setTravelersInRoom(i)
            }

          }
        }
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';


  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__search,
  .style-chooser .vs__open-indicator {
    background: #d5d5d5;
    border: none;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;
  }


</style>
