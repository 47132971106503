<template>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <div  class="mt-1">

           <GroupReservationSummary
            :current-agent="currentAgent"
            :current-group-id="currentGroupId"
            :current-reservation="currentReservation"
            :currentPackages="currentPackages"
            :has-exceeded-inventory="hasExceededInventory"
            :payment-enabled="paymentEnabled"
            />
            <group-reservation-payment
              v-if="paymentEnabled"
              ref="payment-form"
              :current-auth-user=currentAuthUser
              :current-reservation=currentReservation
              @enablePaymentButton=enablePaymentButton
              @submit="submitReservation" />

          <b-card
            v-if="currentReservation.status != 'SUBMITTED' && hasPrimaryContact && !hasExceededInventory "
            title="Confirmation de la réservation des voyageurs"
            header-tag="header"
            body-class="text-center" >
            <b-card-text>
              Votre réservation est prête à être soumise.
            </b-card-text>


            <b-button
              variant="success"
              class="mr-1"
              @click="submitReservation"
              :disabled="!isSubmitButtonEnable || loading"
            >
              <span v-if="paymentEnabled" >Confirmer et payer</span>
              <span v-else>Confirmer</span>
            </b-button>
          </b-card>

        </div>
      </b-col>
    </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BIconFileEarmarkEasel, BSpinner } from 'bootstrap-vue'
import GroupReservationPackageModal from './GroupReservationPackageModal.vue'
import GroupReservationSummary from './GroupReservationSummary.vue'
import { ref, computed, onMounted } from '@vue/composition-api'
import GroupReservationPayment from './GroupReservationPayment.vue'
import { useApplicationContext } from "@/shared/composables/use-application-context";

import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";

export default {
    components: {
        BTabs,
        BTab,
        BRow,
        BCol,
        BCard,
        BCardText,
        BButton,
        BSpinner,
        GroupReservationPackageModal,
        GroupReservationSummary,
        GroupReservationPayment
    },
    props: ['currentPackages', 'currentReservation', 'currentAgent', 'errors', 'currentGroupId', 'currentAuthUser', 'loading'],
    setup(props) {
        const { displaySuccessMessage, displayErrorMessage } = useToastNotification();
        const { refOf, forceUpdate, store } = useApplicationContext();


        let paymentEnabled = ref(false);
        let isSubmitButtonEnable = ref(false);

        try {

            if (store.state[AUTH_STORE_NAMESPACE].orgSettings.payment_enable) {
                paymentEnabled.value = store.state[AUTH_STORE_NAMESPACE].orgSettings.payment_enable;
            } else {
                isSubmitButtonEnable.value = true;

            }

        } catch (e) {
            console.error(e)
        }


        const hasPrimaryContact = computed(() => {
            let reservationOwner = false
            for (let i in props.currentReservation.package_reservations) {
                const element = props.currentReservation.package_reservations[i]
                reservationOwner = element.travelers.find(traveler => {
                    return traveler.reservation_owner === true;
                })

                if (reservationOwner) {
                    break
                }
            }

            if (reservationOwner) {
                return true
            } else {
                return false
            }
        })

        const hasExceededInventory = computed(() => {
            let inventoryExceeded = false;
            props.currentReservation.package_reservations?.forEach(reservedPackage => {
                reservedPackage.group_package.rooms.forEach(room => {
                    if (room.room_remaining_inventory.remaining_places < 0) {
                        inventoryExceeded = true;
                    }
                });
            });

            return inventoryExceeded;
        })

        const pay = async () => {
            try {
                return refOf('payment-form').handleSubmit()
            } catch (e) {
                console.error(e)
            }
        }

        const enablePaymentButton = (value) => {
            isSubmitButtonEnable.value = value
        }


        return {
            displayErrorMessage,
            displaySuccessMessage,
            hasPrimaryContact,
            hasExceededInventory,
            pay,
            paymentEnabled,
            isSubmitButtonEnable,
            enablePaymentButton
        }
    },
    data() {
        return {
        readyToSubmit: true,
        }
    },
    methods: {
        submitReservation() {
        this.$emit('submitReservation',this.currentReservation);
        },
    }
}
</script>
